import { Artist } from 'shared/services/artists.service';

import { ArtistsStateReducerAction, ArtistsTypes } from './types';

export type ArtistsState = {
  readonly artists: Artist[];
  readonly loading: boolean;
  readonly error: boolean | Error;
};

export const INITIAL_STATE: ArtistsState = {
  artists: [],
  loading: false,
  error: false,
};

const artistsReducer = (state = INITIAL_STATE, action: ArtistsStateReducerAction): ArtistsState => {
  switch (action.type) {
    case ArtistsTypes.ARTISTS_GET:
      return { ...state, loading: true };
    case ArtistsTypes.ARTISTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        artists: action.payload,
      };
    case ArtistsTypes.ARTISTS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default artistsReducer;
