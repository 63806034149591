import { Song } from 'shared/services/songs.service';

import { SongPlayer } from './actions';
import { SongsStateReducerAction, SongsTypes } from './types';

export type SongsState = {
  readonly songs: Song[];
  readonly loading: boolean;
  readonly currentPlaying: SongPlayer | null;
  readonly error: boolean | Error;
};

export const INITIAL_STATE: SongsState = {
  songs: [],
  currentPlaying: null,
  loading: false,
  error: false,
};

const songsReducer = (state = INITIAL_STATE, action: SongsStateReducerAction): SongsState => {
  switch (action.type) {
    case SongsTypes.SONGS_GET:
      return { ...state, loading: true };
    case SongsTypes.SONGS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        songs: action.payload,
      };
    case SongsTypes.SONGS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SongsTypes.SONGS_PLAY_SONG:
      return {
        ...state,
        currentPlaying: action.payload,
      };
    default:
      return state;
  }
};

export default songsReducer;
