import { BadgeStatus } from 'shared/components/Badge';

export const gridSpacing = 3;
export const drawerWidth = 310;
export const appDrawerWidth = 320;
export const maxApplicationWidth = 1500;
export const breakpoints = {
  xxs: 0,
  xs: '425px',
  sm: '600px',
  md: '768px',
  lg: '1024px',
  xl: '1400px',
};
export const cardBrandsNameById: { [key: string]: string } = {
  ELO: 'Elo',
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  GPAY: 'Google Pay',
  PAYPAL: 'PayPal',
  AMEX: 'Amex',
};

type badgeTypeByStatusObject = {
  [key: string]: BadgeStatus;
};

export const badgeTypeByStatus: badgeTypeByStatusObject = {
  INITIAL: 'pending',
  PENDING: 'pending',
  INCOMPLETE: 'pending',
  PAID: 'success',
  NOT_PAID: 'canceled',
  CANCELED: 'canceled',
};

export const badgeTraslateValues: { [key: string]: string } = {
  PENDING: 'Pendente',
  INCOMPLETE: 'Incompleta',
  NOT_PAID: 'Não paga',
  PAID: 'Paga',
  INITIAL: 'Inicial',
  CANCELED: 'Cancelada',
};
