import styled, { css } from 'styled-components';

type WrapperProps = {
  isOpen?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isOpen }) => css`
    cursor: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    background: ${theme.colors.darkBackground};
    color: ${theme.colors.grey50};
    margin-top: 3.5rem;
    z-index: ${theme.layers.alwaysOnTop};
    border: 1px solid ${theme.colors.darkBackgroundSecondary};
    border-radius: 0.5rem;

    transition: transform 0.2s ease-in, opacity 0.3s ease-in-out;
    ${isOpen && wrapperModifiers.open()}
    ${!isOpen && wrapperModifiers.close()}
  `}
`;

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
    visibility: hidden;
  `,
};
