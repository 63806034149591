import { useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, IconButton, InputAdornment, Typography, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconSearch, IconX } from '@tabler/icons';
import { debounce } from 'lodash';

import Dropdown from 'shared/components/DropDown';
import SearchResults from 'shared/components/SearchResults';
import { Artist, getArtists, GetArtistsParams } from 'shared/services/artists.service';
import { getSongs, GetSongsParams, Song } from 'shared/services/songs.service';

import { useSessionStorage } from 'hooks/useSessionStorage';

import { OutlineInputStyle, SearchBox, SearchBoxForm } from './styles';

const searchTypeOptions = ['song', 'artist'] as const;

type SearchType = typeof searchTypeOptions[number];
interface SearchData {
  params: GetSongsParams | GetArtistsParams;
  searchType: SearchType;
}

type SearchResultsType = (Song | Artist)[];

const SearchSection = () => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isInfityLoading, setIsInfityLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultsType>([]);
  const [currentSearchResults, setCurrentSearchResults] = useState<SearchResultsType>([]);

  const {
    state: storagedSearchType,
    setValue: setStoragedSearchType,
    removeValue: removeStoragedSearchType,
  } = useSessionStorage<SearchType>({
    key: '@sombank:search-type',
    initialValue: 'song',
  });

  const [searchType, setSearchType] = useState<SearchType>(storagedSearchType);

  useEffect(() => {
    setStoragedSearchType(searchType);

    return () => {
      removeStoragedSearchType();
    };
  }, [searchType]);

  const handleToggleSearchType = (value: SearchType) => {
    setSearchType(value);
  };

  const getSearchedData = async ({ params: { page = 1, filter }, searchType }: SearchData) => {
    try {
      const requestBySearchType = {
        song: getSongs,
        artist: getArtists,
      };

      const filtersByType = {
        song: { page, filter: `name:${filter}` },
        artist: { page, filter: `byname:${filter}` },
      };

      const response = await requestBySearchType[searchType]({ ...filtersByType[searchType] });

      if (page > 1) {
        setSearchResults(prev => [...prev, ...response?.data]);
        setCurrentSearchResults(response?.data);
      } else {
        setCurrentSearchResults(response?.data);
        setSearchResults(response?.data);
      }
    } catch (error) {
      console.log('search error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(
    debounce(async (query, searchType: SearchType) => {
      if (!!query) {
        await getSearchedData({ params: { filter: query, page }, searchType: searchType });
      }
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onChangeSearch = (query = '') => {
    if (!query) {
      setSearchResults([]);
      setIsLoading(false);
      setSearchQuery('');
      setPage(1);
    } else {
      setIsLoading(true);
      setSearchQuery(query);
      debouncedSearch(query, searchType);
    }
  };

  const scrollHandler = async (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight;
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    const isScrolledToBottom = Math.round(scrollTop) === scrollHeight - containerHeight;
    const perPageResults = 10;

    if (isScrolledToBottom && currentSearchResults?.length == perPageResults && !isInfityLoading) {
      setIsInfityLoading(true);
      await getSearchedData({ params: { filter: searchQuery, page: page + 1 }, searchType });
      setIsInfityLoading(false);
    }
  };

  return (
    <SearchBoxForm autoComplete="off" onSubmit={e => e.preventDefault()}>
      <SearchBox sx={{ display: { sm: 'flex' } }}>
        <OutlineInputStyle
          type="text"
          name="search"
          autoComplete="new-password"
          id="input-search-header"
          value={searchQuery}
          onChange={e => onChangeSearch(e.target.value)}
          placeholder="Procurar"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          endAdornment={
            <>
              {/* <IconButton color="info" aria-label="search-loading">
                <IconAdjustments size={20} />
              </IconButton> */}
              {isLoading ? (
                <IconButton color="info" aria-label="search-loading">
                  <CircularProgress color="info" size={20} />
                </IconButton>
              ) : searchQuery ? (
                <IconButton onClick={() => onChangeSearch('')} color="info" aria-label="search-clear">
                  <IconX size={20} />
                </IconButton>
              ) : (
                <Select
                  label="Filtrar por"
                  onChange={e => handleToggleSearchType(e.target.value as SearchType)}
                  value={searchType}>
                  <MenuItem value="song">Obra</MenuItem>
                  <MenuItem value="artist">Artista/Compositor</MenuItem>
                </Select>
              )}
            </>
          }
          aria-describedby="search-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
        />
        <Dropdown open={!!searchQuery && !isLoading}>
          {searchResults?.length > 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                '.MuiCircularProgress-root': {
                  margin: '0 auto',
                },
              }}
              onScroll={scrollHandler}
              maxHeight={{ xxs: 'calc(100vh - 12rem)', xs: 'calc(100vh - 10rem)' }}
              overflow="auto"
              padding="0.5rem">
              {searchResults?.map((item, index) => (
                <SearchResults
                  type={searchType}
                  key={index}
                  id={item?.id}
                  artist={'writers' in item ? item?.writers[0]?.pseudonym : item?.name}
                  img={'banner_url' in item ? item?.banner_url : item?.avatar_url}
                  music={'pseudonym' in item ? item.pseudonym : item?.name}
                  onClick={() => setSearchQuery('')}
                />
              ))}
              {isInfityLoading && <CircularProgress disableShrink size={24} />}
            </Box>
          ) : (
            <Typography textAlign="center" variant="h6" p={2}>
              Nenhum resultado encontrado
            </Typography>
          )}
        </Dropdown>
      </SearchBox>
    </SearchBoxForm>
  );
};

export default SearchSection;
