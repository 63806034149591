import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Session = Loadable(lazy(() => import('modules/authorization/pages/Session')));
const ForgotPassword = Loadable(lazy(() => import('modules/authorization/pages/ForgotPassword')));
const Register = Loadable(lazy(() => import('modules/authorization/pages/Register')));
const ResetPassword = Loadable(lazy(() => import('modules/authorization/pages/ResetPassword')));

const sessionRouter = [
  {
    path: '/login',
    element: <Session />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/reset-password/:id',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
];

export default sessionRouter;
