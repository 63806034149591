import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { appConfig } from 'config/app';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

export const AuthGuard: React.FC<any> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      const prevUrl = `${location?.pathname}${location?.search}`;
      localStorage.setItem('@sombank:prevUrl', prevUrl);

      navigate(appConfig.loginPath, { replace: true });

      addToast({
        type: 'info',
        title: 'É preciso se autenticar para continuar navegando',
        description: 'Inicie sua sessão e continue utilizando a plataforma',
        duration: 10000,
      });
    }
  }, [navigate, user]);

  return children;
};
