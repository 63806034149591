import { ButtonBase } from '@mui/material';
import { styled, shouldForwardProp } from '@mui/system';

export const NavButton = styled(ButtonBase, { shouldForwardProp })(({ theme }) => ({
  background: theme.colors.darkPrimaryMain,
  color: theme.colors.grey50,
  fontWeight: 'bold',
  padding: '0.5rem 1rem',
  borderRadius: '0.5rem',
  marginTop: theme.spacings.xxsmall,
  marginLeft: '1rem',
  display: 'flex',
  flex: 1,
}));
