import { Suspense, LazyExoticComponent, ComponentType } from 'react';

import Loader, { LoaderProps } from './Loader';

type LazyPage = LazyExoticComponent<() => JSX.Element> | ComponentType<React.ReactNode>;

export const Loadable = (Component: LazyPage) => (props: LoaderProps) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
