import { Box, Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { gridSpacing } from 'shared/store/constant';

export interface NavManualProps {
  title: string;
}

export const NavManual: React.FC<NavManualProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        marginBottom: theme.spacing(gridSpacing),
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
        background: theme.palette.dark[200],
      }}>
      <Box sx={{ p: 2, pl: 2 }}>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
          {title && (
            <Grid item>
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {title}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
};
