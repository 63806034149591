import { createTheme, ThemeOptions, Theme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';
import { ColorProps, CustomizationStateProps } from 'interfaces/global.interface';

import font from 'shared/styles/themes/font';
import grid from 'shared/styles/themes/grid';
import layers from 'shared/styles/themes/layers';
import spacings from 'shared/styles/themes/spacings';

import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import customShadows from './shadows';
import themeTypography from './typography';

export const theme = (customization: CustomizationStateProps) => {
  let color: ColorProps;

  switch (customization.presetColor) {
    case 'default':
    default:
      color = colors;
  }

  const themeOption = {
    colors: color,
    heading: '',
    paper: '',
    backgroundDefault: '',
    backgroundSecondary: '',
    background: '',
    darkTextPrimary: '',
    darkTextSecondary: '',
    darkTextDefault: '',
    textDark: '',
    menuSelected: '',
    menuSelectedBack: '',
    divider: '',
    customization,
  };

  switch (customization.navType) {
    case 'dark':
      themeOption.paper = color.darkLevel2;
      themeOption.backgroundDefault = color.darkPaper;
      themeOption.backgroundSecondary = color.darkBackgroundSecondary;
      themeOption.background = color.darkBackground;
      themeOption.darkTextPrimary = color.darkTextPrimary;
      themeOption.darkTextSecondary = color.darkTextSecondary;
      themeOption.textDark = color.darkTextPrimary;
      themeOption.menuSelected = color.darkTextTitle;
      themeOption.menuSelectedBack = color.darkBackground;
      themeOption.divider = color.darkTextPrimary;
      themeOption.heading = color.darkTextTitle;
      themeOption.darkTextDefault = color.darkTextDefault;
      break;
    case 'light':
    default:
      themeOption.paper = color.paper;
      themeOption.backgroundDefault = color.paper;
      themeOption.background = color.primaryLight;
      themeOption.darkTextPrimary = color.grey700;
      themeOption.darkTextSecondary = color.grey500;
      themeOption.textDark = color.grey900;
      themeOption.menuSelected = color.secondaryDark;
      themeOption.menuSelectedBack = color.secondaryLight;
      themeOption.divider = color.grey200;
      themeOption.heading = color.grey900;
      break;
  }

  const themeOptions: ThemeOptions = {
    breakpoints: {
      values: {
        xxs: 0,
        xs: 425,
        sm: 600,
        md: 768,
        lg: 1024,
        xl: 1400,
      },
    },
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    typography: themeTypography(themeOption),
    customShadows: customShadows(customization.navType, themeOption),
    colors: themeOption.colors,
    font,
    spacings,
    layers,
    grid,
  };

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
