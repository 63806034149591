import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 0 0 calc(${theme.spacings.xxsmall} / 4) 1rem;
    padding: 0.75rem 1.2rem 0.75rem 1rem;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.darkBackground};
      border-radius: 0.75rem;
    }

    button {
      z-index: ${theme.layers.base};
      background: ${theme.colors.darkBackgroundSecondary};
    }
  `}
`;

export const AvatarGroup = styled.div`
  ${({ theme }) => css`
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-right: 1rem;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      border-radius: 100%;
      box-shadow: inset 0 0 0 1px ${theme.colors.darkTextDefault};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;

    h4,
    h5 {
      list-style: none;
      text-decoration: none;
      color: ${theme.colors.darkTextDefault};
    }

    h4 {
      font-weight: 400;
    }

    h5 {
      font-weight: 300;
      font-size: 0.6rem;
      margin-top: 0.125rem;
    }
  `}
`;
