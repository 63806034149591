import { Wrapper } from './styles';

export type DropdownProps = {
  open: boolean;
  children: React.ReactNode;
};

const Dropdown = ({ children, open = false }: DropdownProps) => {
  return <Wrapper isOpen={open}> {children}</Wrapper>;
};

export default Dropdown;
