import { ActionType } from 'typesafe-actions';

import * as artistsActions from './actions';

export enum ArtistsTypes {
  ARTISTS_GET = '@Artists/ARTISTS_GET',
  ARTISTS_GET_SUCCESS = '@Artists/ARTISTS_GET_SUCCESS',
  ARTISTS_GET_ERROR = '@Artists/ARTISTS_GET_ERROR',
}

export type ArtistsStateReducerAction = ActionType<typeof artistsActions>;
