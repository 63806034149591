export default {
  light: 300,
  normal: 400,
  bold: 600,
  sizes: {
    xsmall: '1.2rem',
    small: '1.4rem',
    medium: '1.6rem',
    large: '1.8rem',
    xlarge: '2.0rem',
    xxlarge: '2.8rem',
  },
} as const;
