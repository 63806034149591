import MainLayout from 'layout/MainLayout';
import { AuthGuard } from 'routes/guard/AuthGuard';

import { artistsRouter } from 'modules/artists/routes/index.routes';
import { cartRouter } from 'modules/cart/routes/index.routes';
import { homeRouter } from 'modules/home/routes/index.routes';
import { mySongsRouter } from 'modules/my-songs/routes/index.routes';
import { profileRouter } from 'modules/profile/routes/index.routes';
import { walletRouter } from 'modules/wallet/routes/index.routes';

import { maxApplicationWidth } from 'shared/store/constant';

export const PrivateRoutes = {
  element: (
    <AuthGuard>
      <div style={{ position: 'relative', width: '100%', maxWidth: maxApplicationWidth, margin: '0 auto' }}>
        <MainLayout />
      </div>
    </AuthGuard>
  ),
  children: [...homeRouter, ...profileRouter, ...mySongsRouter, ...walletRouter, ...artistsRouter, ...cartRouter],
};

export default PrivateRoutes;
