import { Typography } from '@mui/material';
import { NavItemType } from 'interfaces/global.interface';

import { User } from 'hooks/auth';

import NavGroup from './NavGroup';

type MenuListProps = {
  menuList: { items: NavItemType[] };
  user?: User;
};

const MenuList = ({ menuList, user }: MenuListProps) => {
  const navItems = menuList.items.map((item, index) => {
    let navItem = item;

    if (user) {
      navItem?.children?.forEach(child => {
        if (child?.unavailableProfiles?.includes(user?.category_type)) {
          navItem = {
            ...item,
            children: item?.children?.filter(item => item?.id !== child?.id),
          };
        }
      });
    }

    switch (navItem.type) {
      case 'group':
        return <NavGroup key={index} item={navItem} />;
      default:
        return (
          <Typography key={index} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
