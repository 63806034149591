import { put, call, all, takeLatest } from 'redux-saga/effects';

import { getArtists } from 'shared/services/artists.service';

import { getArtistsError, getArtistsSuccess } from './actions';
import { ArtistsTypes } from './types';

// TODO: ENTENDER PQ SEMPRE TA CHAMANDO TODOS AQUI
function* callArtistsApi(): any {
  try {
    const { data } = yield call(getArtists);

    yield put(getArtistsSuccess(data));
  } catch (err) {
    yield put(getArtistsError(err as Error));
  }
}

export function* watchGetArtists() {
  yield all([takeLatest(ArtistsTypes.ARTISTS_GET, callArtistsApi)]);
}
