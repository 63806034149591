import { combineReducers } from 'redux';

import artistsReducer from './ducks/artists';
import customizationReducer from './ducks/customization/customizationReducer';
import songsReducer from './ducks/songs';

export const reducer = combineReducers({
  artists: artistsReducer,
  customization: customizationReducer,
  customization1: customizationReducer,
  songs: songsReducer,
});

export type RootReducerState = ReturnType<typeof reducer>;
