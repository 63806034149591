import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import { Loadable } from 'shared/ui-component/Loadable';

import { AuthRoutes } from './AuthRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

export function Routes(): any {
  const MaintenanceError = Loadable(lazy(() => import('modules/maintenance/Error')));

  return useRoutes([
    PublicRoutes,
    PrivateRoutes,
    AuthRoutes,
    {
      path: '*',
      element: <MaintenanceError />,
    },
  ]);
}
