import { Avatar, Box, ButtonBase, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconMenu2 } from '@tabler/icons';

import { drawerWidth } from 'shared/store/constant';

import { useAuth } from 'hooks/auth';

import LogoSection from '../LogoSection';
import NavList from './NavList';
import ProfileSection from './ProfileSection';
import SearchSection from './SearchSection';

import { NavBox } from './styles';

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleLeftDrawerToggle }) => {
  const { user } = useAuth();
  const theme = useTheme();

  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            width: '100%',
            minWidth: drawerWidth - 16,
          },
          maxWidth: drawerWidth - 16,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            width: '100%',
          },
        }}>
        <ButtonBase
          sx={{
            borderRadius: '0.75rem',
            overflow: 'hidden',
            display: { xs: 'block', lg: 'none' },
            marginRight: '1rem',
            background: theme.colors.darkBackgroundSecondary,
          }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.colors.darkBackgroundSecondary,
              color: theme.colors.primaryMain,
              '&:hover': {
                background: theme.colors.darkBackgroundSecondary,
                color: theme.colors.primaryMain,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit">
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
          }}>
          <LogoSection />
        </Box>
      </Box>

      {!matchDownSm && <SearchSection />}
      <NavBox sx={{ flexGrow: 1, display: { lg: 'block' } }}>
        <NavList user={user} />
      </NavBox>
      {/* <NotificationButton color="info" aria-label="search-filter">
        <IconBell color={theme.palette.text.heading} />
      </NotificationButton> */}
      <ProfileSection />
    </>
  );
};

export default Header;
