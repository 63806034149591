import { useState, useEffect } from 'react';
interface Size {
  width: number;
  height: number;
  isMobile: boolean;
}

const MOBILE_WIDTH = 640;

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: document.body.clientWidth,
    height: window.innerHeight,
    isMobile: document.body.clientWidth < MOBILE_WIDTH,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.body.clientWidth,
        height: window.innerHeight,
        isMobile: document.body.clientWidth < MOBILE_WIDTH,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
