import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Profile = Loadable(lazy(() => import('modules/profile/pages/Index')));

export const profileRoutes = [
  {
    path: '/profile',
    element: <Profile />,
  },
];
