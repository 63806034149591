import axios, { AxiosError } from 'axios';

import { signOutForce } from 'hooks/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API ?? 'https://api.sombank.app',
  headers: { 'Access-Control-Allow-Origin': '*' },
});

// TODO: Colocar o interceptor AppError para o alert
api.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      signOutForce({ forceRedirect: true });
    }

    return Promise.reject(error);
  },
);

export default api;
