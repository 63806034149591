import { ButtonBase, styled } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

export const CustomButtonBase = styled(ButtonBase, { shouldForwardProp })(({ theme }) => ({
  img: {
    marginRight: '1rem',
  },

  [theme.breakpoints.down('md')]: {
    img: {
      width: '120px',
    },
  },
}));
