import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const MySongs = Loadable(lazy(() => import('modules/my-songs/pages/Index')));

export const mySongsRoutes = [
  {
    path: '/my-songs',
    element: <MySongs />,
  },
];
