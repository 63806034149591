import MainLayout from 'layout/MainLayout';

import { artistsRouter } from 'modules/artists/routes/index.routes';
import { homeRouter } from 'modules/home/routes/index.routes';
import { releasesRouter } from 'modules/releases/routes/index.routes';
import { songRequestRouter } from 'modules/song-request/routes/index.routes';

import { maxApplicationWidth } from 'shared/store/constant';

export const PublicRoutes = {
  element: (
    <div style={{ position: 'relative', width: '100%', maxWidth: maxApplicationWidth, margin: '0 auto' }}>
      <MainLayout />
    </div>
  ),
  children: [...homeRouter, ...releasesRouter, ...artistsRouter, ...songRequestRouter],
};

export default PublicRoutes;
