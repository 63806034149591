import { appConfig } from 'config/app';
import { CustomizationStateProps, DefaultRootStateProps } from 'interfaces/global.interface';

import * as actionTypes from './actions';

export const initialState: DefaultRootStateProps['customization'] = {
  isOpen: [], // for active default menu
  fontFamily: appConfig.fontFamily,
  borderRadius: appConfig.borderRadius,
  outlinedFilled: appConfig.outlinedFilled,
  navType: appConfig.theme,
  presetColor: appConfig.presetColor,
  opened: true,
};

const customizationReducer = (state = initialState, action: CustomizationStateProps): any => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };

    case actionTypes.MENU_TYPE:
      return {
        ...state,
        navType: action.navType,
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    default:
      return state;
  }
};

export default customizationReducer;
