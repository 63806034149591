import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { appConfig } from 'config/app';

import { useAuth } from 'hooks/auth';

export const GuestGuard: React.FC<any> = ({ children }) => {
  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate(appConfig.defaultPlatformPath, { replace: true });
    }
  }, [navigate]);

  return children;
};
