import { put, call, all, takeLatest } from 'redux-saga/effects';

import { getSongs } from 'shared/services/songs.service';

import { getSongsError, getSongsSuccess } from './actions';
import { SongsTypes } from './types';

function* callSongsApi(): any {
  try {
    const { data } = yield call(getSongs);

    yield put(getSongsSuccess(data));
  } catch (err) {
    yield put(getSongsError(err as Error));
  }
}

export function* watchGetSongs() {
  yield all([takeLatest(SongsTypes.SONGS_GET, callSongsApi)]);
}
