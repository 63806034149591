/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Badge,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCart } from '@react-providers/cart';
import { DefaultRootStateProps, LinkTarget, NavigationOptions, NavItemType } from 'interfaces/global.interface';

import { MENU_OPEN, SET_MENU } from 'shared/store/ducks/customization/actions';

export interface NavItemProps {
  item: NavItemType;
  level: number;
  isNavList?: boolean;
}
const NavItem: React.FC<NavItemProps> = ({ item, level, isNavList = false }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { cart } = useCart();
  const dispatch = useDispatch();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size={isNavList ? '1.125rem' : '1.3rem'} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex(id => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex(id => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string | NavigationOptions;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <LinkScroll to={(item?.hash as string) || ''} duration={500} smooth={true} offset={-130}>
        <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
      </LinkScroll>
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    dispatch({ type: MENU_OPEN, id });
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex(id => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        color: isNavList ? theme.palette.text.heading : theme.colors.darkTextDefault,
        borderRadius: `${customization.borderRadius}px`,
        mb: isNavList ? 0 : 0.5,
        alignItems: 'center',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        padding: isNavList ? '8px' : '14px 24px',
        ml: isNavList ? '12px' : 0,
        '& .MuiListItemIcon-root': {
          color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextDefault,
        },
        ':hover': {
          backgroundColor: theme.colors.darkBackground,
          color: theme.colors.darkTextTitle,
          '& .MuiListItemIcon-root': {
            color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextTitle,
          },
        },
        '&.Mui-selected': {
          color: theme.colors.darkTextTitle,
          backgroundColor: theme.colors.darkBackground,
          '&:hover': {
            backgroundColor: theme.colors.darkBackground,
          },
          '& .MuiListItemIcon-root': {
            color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextTitle,
          },
        },
      }}
      selected={!!pathname?.includes(item?.url as string)}
      onClick={() => itemHandler(item.id!)}>
      <ListItemIcon
        sx={{
          background: isNavList ? theme.palette.dark[700] : 'none',
          my: 'auto',
          minWidth: !item?.icon ? 18 : isNavList ? 'auto' : 35,
          justifyContent: isNavList ? 'center' : 'left',
          borderRadius: isNavList ? '8px' : 'none',
          padding: isNavList ? '4px' : '0',
          mr: isNavList && item.title ? '8px' : '0',
        }}>
        {item.hasBadge ? (
          <Badge badgeContent={cart.totalQuantity} color="error">
            {itemIcon}
          </Badge>
        ) : (
          itemIcon
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            whiteSpace="nowrap"
            fontSize={isNavList ? '14px' : '16px'}
            variant={customization.isOpen.findIndex(id => id === item.id) > -1 ? 'h5' : 'body1'}
            color="inherit">
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
