import { IconUser, IconCurrencyDollar, IconHeadphones } from '@tabler/icons';

// como adicionar uma validação nas rotas quando não tiver user?

const main = {
  id: 'main-route',
  type: 'group',
  pattern: 'module.home.index',
  children: [
    {
      id: 'my-songs',
      title: 'Gerenciador',
      type: 'item',
      pattern: 'my-songs.module.show',
      url: '/my-songs',
      icon: IconUser,
      unavailableProfiles: ['SINGLE', 'GROUP'],
      hasBadge: false,
    },
    {
      id: 'wallet',
      title: 'Liberações',
      type: 'item',
      pattern: 'wallet.module.show',
      url: '/wallet',
      icon: IconCurrencyDollar,
      hasBadge: false,
    },
    {
      id: 'cart',
      title: '',
      type: 'item',
      pattern: 'wallet.module.show',
      url: '/cart',
      icon: IconHeadphones,
      hasBadge: true,
    },
  ],
};

export default main;
