import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Home = Loadable(lazy(() => import('modules/home/pages/Index')));
const Policy = Loadable(lazy(() => import('modules/home/pages/Policy')));
const Terms = Loadable(lazy(() => import('modules/home/pages/Terms')));

export const homeRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/avisodeprivacidade',
    element: <Policy />,
  },
  {
    path: '/termos',
    element: <Terms />,
  },
];
