import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigationOptions } from 'interfaces/global.interface';

import { NavButton } from './styles';

type ButtonItemProps = {
  url?: string | NavigationOptions;
  title?: string | ReactNode;
  disabled?: boolean;
};

export default function ButtonItem({ title, url, disabled = false }: ButtonItemProps) {
  const navigate = useNavigate();

  return (
    <NavButton disabled={disabled} sx={{ opacity: disabled ? 0.5 : 1 }} onClick={() => navigate(url as string)}>
      {title as string}
    </NavButton>
  );
}
