import { action } from 'typesafe-actions';

import { Artist } from 'shared/services/artists.service';

import { ArtistsTypes } from './types';

export const getArtists = (data?: { page?: number; per_page?: number }) => {
  return action(ArtistsTypes.ARTISTS_GET, data);
};

export const getArtistsSuccess = (data: Artist[]) => {
  return action(ArtistsTypes.ARTISTS_GET_SUCCESS, data);
};

export const getArtistsError = (error: Error) => {
  return action(ArtistsTypes.ARTISTS_GET_ERROR, error);
};
