import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Wallet = Loadable(lazy(() => import('modules/wallet/pages/Index')));

export const walletRoutes = [
  {
    path: '/wallet',
    element: <Wallet />,
  },
];
