import { useNavigate } from 'react-router-dom';

import { appConfig } from 'config/app';

import Logo from 'shared/ui-component/Logo';

import { CustomButtonBase } from './styles';

const LogoSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <CustomButtonBase disableRipple onClick={() => navigate(appConfig.defaultPath)}>
      <Logo />
    </CustomButtonBase>
  );
};

export default LogoSection;
