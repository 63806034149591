import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Artists = Loadable(lazy(() => import('modules/artists/pages/Index')));

export const artistsRoutes = [
  {
    path: '/artists',
    element: <Artists />,
  },
];
