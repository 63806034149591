import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin: 0 0 ${theme.spacings.xxsmall};

    &:hover {
      background: ${theme.colors.darkBackgroundSecondary};
      border-radius: 0.75rem;
    }
  `}
`;

export const TextGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.5rem;
`;

export const AvatarGroup = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    border-radius: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type ContentProps = {
  isMobile: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ theme, isMobile }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;

    ${TextGroup} {
      flex-direction: ${isMobile ? 'column' : 'row'};

      h5 {
        list-style: none;
        text-decoration: none;
        font-weight: 300;
        font-size: ${isMobile ? '0.6rem' : '0.75'};
        margin: ${isMobile ? '0 0 0.2rem' : '0 0.5rem 0'};
        color: ${theme.colors.darkTextDefault};
        white-space: nowrap;
        position: relative;
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.2rem;
        }

        &::before {
          content: '';
          display: ${isMobile ? 'none' : 'block'};
          width: 1px;
          height: 80%;
          background: ${theme.colors.darkTextDefault};
          position: absolute;
          left: -0.5rem;
        }
      }

      h4 {
        font-weight: 400;
        font-size: ${isMobile ? '0.75rem' : '1rem'};
        white-space: nowrap;
        color: ${theme.colors.grey50};
        margin: ${isMobile ? '0 0 0.2rem' : '0 0.5rem 0'};
      }
    }

    button {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.4rem 1rem;
      margin: ${isMobile ? '0.25rem 0 0.25rem 0' : '0.25rem 0 0.25rem auto'};
    }
  `}
`;
