/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link as LinkRouter } from 'react-router-dom';
import { Link } from 'react-scroll';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { DefaultRootStateProps } from 'interfaces/global.interface';

import ArtistNavItem from '../ArtistNavItem';
import ButtonItem from '../ButtonItem';
import { NavGroupProps } from '../NavGroup';
import NavItem from '../NavItem';

export interface NavCollapseProps {
  menu: NavGroupProps['item'];
  level: number;
  isNavList?: boolean;
}

const NavCollapse: React.FC<NavCollapseProps> = ({ menu, level, isNavList }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<string | null | undefined>(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  // menu collapse & item
  const menus = menu.children?.map((item, index) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={index} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={index} item={item} level={level + 1} />;
      case 'artist':
        return (
          <LinkRouter to={`/releases?artist_id=${item?.id}`} style={{ textDecoration: 'none' }}>
            <ArtistNavItem key={index} {...item} />
          </LinkRouter>
        );
      case 'button':
        return <ButtonItem key={index} {...item} />;
      default:
        return (
          <Typography key={index} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon!;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size={isNavList ? '1.125rem' : '1.3rem'}
      style={{ marginTop: 'auto', marginBottom: 'auto' }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <Link to={open === false ? (menu?.hash as string) || '' : ''} duration={500} smooth={true} offset={-130}>
      <ListItemButton
        sx={{
          color: isNavList ? theme.palette.text.heading : theme.colors.darkTextDefault,
          borderRadius: `${customization.borderRadius}px`,
          mb: isNavList ? 0 : 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
          padding: isNavList ? '8px' : '14px 24px',
          ml: isNavList ? '12px' : 0,
          '& .MuiListItemIcon-root': {
            color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextDefault,
          },
          ':hover': {
            backgroundColor: theme.colors.darkBackground,
            color: theme.colors.darkTextTitle,
            '& .MuiListItemIcon-root': {
              color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextTitle,
            },
          },
          '&.Mui-selected': {
            color: theme.colors.darkTextTitle,
            backgroundColor: theme.colors.darkBackground,
            '&:hover': {
              backgroundColor: theme.colors.darkBackground,
            },
            '& .MuiListItemIcon-root': {
              color: isNavList ? theme.colors.primaryMain : theme.colors.darkTextTitle,
            },
          },
        }}
        selected={selected === menu.id || !!pathname?.includes(menu?.url as string)}
        onClick={handleClick}>
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={selected === menu.id || !!pathname?.includes(menu?.url as string) ? 'h5' : 'body1'}
              color="inherit"
              sx={{ my: 'auto' }}>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </Link>
  );
};

export default NavCollapse;
