import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCart } from '@react-providers/cart';
import { IconLogout, IconSettings, IconUser, IconHeadphones } from '@tabler/icons';
import { DefaultRootStateProps } from 'interfaces/global.interface';

import MainCard from 'shared/ui-component/cards/MainCard';
import Transitions from 'shared/ui-component/extended/Transitions';

import { useAuth } from 'hooks/auth';

const ProfileSection: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<any>(null);

  const { signOut } = useAuth();
  const {
    removeCartItem,
    cart: { cartItems },
  } = useCart();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {user ? (
        <>
          <Chip
            sx={{
              marginLeft: '1.5rem',
              height: { xs: '34px', sm: '48px' },
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
              backgroundColor:
                theme.palette.mode === 'dark' ? theme.colors.darkBackgroundSecondary : theme.palette.primary.light,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.colors.darkBackgroundSecondary,
                background: `${theme.colors.darkBackgroundSecondary}!important`,
              },
              '& .MuiChip-label': {
                lineHeight: 0,
              },
              '.MuiChip-label svg': {
                stroke: theme.colors.primaryMain,
              },
              '.MuiAvatar-root': {
                color: theme.colors.darkLevel1,
              },
            }}
            icon={
              <Avatar
                src={user?.avatar_url as string}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.smallAvatar,
                  },
                  ...theme.typography.mediumAvatar,
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer',
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="red"
              />
            }
            label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          />
          <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 14],
                  },
                },
              ],
            }}>
            {({ TransitionProps }) => (
              <Transitions in={open} {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard border={false} elevation={16} content={false} shadow={theme.shadows[16]}>
                      <Box sx={{ p: 2 }}>
                        <Stack mb={1}>
                          <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="h4">Olá,</Typography>
                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                              {user?.name}
                            </Typography>
                          </Stack>
                          <Typography variant="subtitle2" marginTop={1}>
                            {user?.enterprise?.permission?.name}
                          </Typography>
                        </Stack>
                        <Divider />
                      </Box>
                      <Box sx={{ p: 1, mt: -2 }}>
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%',
                            },
                          }}>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                              paddingLeft: '0.8rem',
                            }}
                            onClick={() => navigate('/profile')}>
                            <ListItemIcon>
                              <IconUser stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Minha conta</Typography>} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                              paddingLeft: '0.8rem',
                            }}
                            onClick={() => navigate('/cart')}>
                            <ListItemIcon>
                              <IconHeadphones stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Meu repertório</Typography>} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            onClick={() => {
                              cartItems.map(item => {
                                removeCartItem(item.product);
                              });
                              signOut();
                            }}>
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                          </ListItemButton>
                        </List>
                      </Box>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </Popper>
        </>
      ) : (
        <>
          <Link style={{ textDecoration: 'none', marginLeft: '1rem' }} to="/login">
            <Button sx={{ whiteSpace: 'nowrap' }} variant="contained">
              Fazer login
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

export default ProfileSection;
