import { action } from 'typesafe-actions';

import { Song } from 'shared/services/songs.service';

import { SongsTypes } from './types';

export type SongPlayer = {
  id: string;
  title: string;
  img: string | null;
  url: string;
  index: number;
};

export const playSong = (data: SongPlayer) => {
  return action(SongsTypes.SONGS_PLAY_SONG, data);
};

export const getSongs = (data?: { page?: number; per_page?: number }) => {
  return action(SongsTypes.SONGS_GET, data);
};

export const getSongsSuccess = (data: Song[]) => {
  return action(SongsTypes.SONGS_GET_SUCCESS, data);
};

export const getSongsError = (error: Error) => {
  return action(SongsTypes.SONGS_GET_ERROR, error);
};
