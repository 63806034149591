import {
  IconUsers,
  IconMusic,
  IconNotification,
  IconHome,
  IconUser,
  IconCurrencyDollar,
  IconHeadphones,
  IconPhone,
} from '@tabler/icons';

const main = {
  id: 'main-route',
  type: 'group',
  pattern: 'module.home.index',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      pattern: 'home.module.show',
      url: 'home',
      hash: 'main',
      icon: IconHome,
      hasBadge: false,
    },
    {
      id: 'releases',
      title: 'Obras',
      type: 'item',
      pattern: 'releases.module.show',
      url: '/home#releases',
      hash: 'releases',
      icon: IconNotification,
      hasBadge: false,
    },
    {
      id: 'genres',
      title: 'Gênero',
      type: 'item',
      pattern: 'genres.module.show',
      url: '/home#genres',
      hash: 'genres',
      icon: IconMusic,
      hasBadge: false,
    },
    {
      id: 'my-songs',
      title: 'Gerenciador',
      type: 'item',
      pattern: 'my-songs.module.show',
      url: '/my-songs',
      icon: IconUser,
      unavailableProfiles: ['SINGLE', 'GROUP'],
      hasBadge: false,
    },
    {
      id: 'wallet',
      title: 'Liberações',
      type: 'item',
      pattern: 'wallet.module.show',
      url: '/wallet',
      icon: IconCurrencyDollar,
      hasBadge: false,
    },
    {
      id: 'artists',
      title: 'Compositores',
      type: 'collapse',
      pattern: 'artists.module.show',
      url: '#artists',
      hash: 'artists',
      icon: IconUsers,
      children: [],
      hasBadge: false,
    },
    {
      id: 'cart',
      title: 'Repertório',
      type: 'item',
      pattern: 'wallet.module.show',
      url: '/cart',
      icon: IconHeadphones,
      hasBadge: true,
    },
    {
      id: 'externalsongs',
      title: '+ Músicas',
      type: 'item',
      pattern: 'external.songs',
      external: true,
      target: true,
      url: 'https://api.whatsapp.com/send/?phone=558591750043&text=Olá Sombank! Gostaria de solicitar uma obra ou esclarecer uma dúvida.',
      icon: IconPhone,
    },
  ],
};

export default main;
