import { Avatar, OutlinedInput, Popper, styled, Box } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

export const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const ResponsiveSearchBox = styled(Box, { shouldForwardProp })(() => ({
  display: 'none',
}));

export const SearchBoxForm = styled('form')(({}) => ({
  width: '100%',
}));

export const SearchBox = styled(Box, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  display: 'none',
  background: theme.palette.dark[800],
  borderRadius: '12px',
  height: '50px',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    display: 'flex !important',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: '1rem',
    width: 'auto',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiIconButton-root': {
    background: theme.palette.dark[700],
    height: '34px',
    width: '34px',
    padding: '4px',
    borderRadius: '12px',
    marginLeft: '1rem',
  },
  '& input': {
    '&::placeholder': {
      color: theme.palette.text.heading,
      opacity: 1,
    },
  },
}));

export const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: '100%',
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
    paddingRight: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 4,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
  },
}));

export const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
  },
}));
