import { useSelector } from 'react-redux';

import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavItemType } from 'interfaces/global.interface';
import { menuItems, menuItemsMobile } from 'menu-items/index';

import { drawerWidth, maxApplicationWidth } from 'shared/store/constant';
import { ArtistsState } from 'shared/store/ducks/artists';
import { RootReducerState } from 'shared/store/rootReducer';

import { useAuth } from 'hooks/auth';
import useWindowSize from 'hooks/useWindowDimensions';

import SearchSection from '../Header/SearchSection';
import LogoSection from '../LogoSection';
import MenuList from './MenuList';

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
}

const Sidebar: React.FC<SidebarProps> = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { width } = useWindowSize();
  const { artists } = useSelector<RootReducerState, ArtistsState>(state => state.artists);
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const formatArtistsNavItems = (items: NavItemType[]) => {
    const firstFourArtists = artists?.slice(0, 4);

    const artistNavItems = [
      ...firstFourArtists?.map(item => ({
        id: item?.id,
        title: item?.pseudonym,
        img: item?.avatar_url,
        segmentName: item?.segment?.name,
        type: 'artist',
      })),
      {
        id: 'seeAllArtists',
        title: 'Ver todos',
        type: 'button',
        url: '/artists',
        disabled: firstFourArtists?.length === 0,
      },
    ];

    return items?.map(item => {
      return {
        ...item,
        children: item?.children?.map(child => {
          if (child?.id === 'artists') {
            return {
              ...child,
              children: artistNavItems,
            };
          }

          return child;
        }),
      };
    });
  };

  menuItems.items = formatArtistsNavItems(menuItems.items);
  menuItemsMobile.items = formatArtistsNavItems(menuItemsMobile.items);

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Box sx={{ display: 'flex', p: 4, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      {matchDownSm && <SearchSection />}
      <Box
        component="div"
        style={{
          padding: '0 1rem 1rem',
          overflowY: 'auto',
        }}>
        <MenuList menuList={matchUpLg ? menuItems : menuItemsMobile} user={user} />
      </Box>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 }, width: matchUpLg ? drawerWidth : 'auto' }}
      aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpLg ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            flex: 1,
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            paddingBottom: theme.spacings.xsmall,
            zIndex: theme.layers.overlay,
            [theme.breakpoints.up('lg')]: {
              top: '115px',
              left: width > maxApplicationWidth ? (width - maxApplicationWidth) / 2 : 0,
              paddingBottom: '9rem',
            },
          },
          '.MuiBox-root.css-z0ao83': {
            marginBottom: theme.spacings.xsmall,
            marginRight: '1rem',
            marginLeft: '1rem',
            width: 'auto',
          },
          '.MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit">
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
