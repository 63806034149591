import { AxiosResponse } from 'axios';

import { Segment } from 'modules/home/services/get-segments.service';

import api from 'shared/services/api';

import { Song } from './songs.service';

export type CreateArtistTypes = 'SINGLE' | 'GROUP' | 'WRITER';

export type Artist = {
  created_at: string;
  id: string;
  name: string;
  email: string;
  phone: string;
  pseudonym: string;
  type: CreateArtistTypes;
  biography: string;
  segment_id: string;
  segment: Segment;
  customer_id: string;
  avatar_url: string | null;
  identify: string;
};

export type GetArtistsParams = {
  filter?: string;
  perPage?: number;
  page?: number;
};

export type CreateArtistData = {
  name: string;
  segment_id?: string;
  type: CreateArtistTypes;
  email?: string;
  phone?: string;
  biography: string;
  pseudonym: string;
  identify: string;
};

export async function getArtists(params?: GetArtistsParams): Promise<AxiosResponse<Artist[]>> {
  return api.get('/v1/artists', { params });
}

export async function getMyArtists(params?: GetArtistsParams): Promise<AxiosResponse<Artist[]>> {
  return api.get('/v1/artists/me', { params });
}

export async function getSongsByArtist(id: string, params?: GetArtistsParams): Promise<AxiosResponse<Song[]>> {
  return api.get(`/v1/artists/${id}/song`, { params });
}

export async function createArtist(data: CreateArtistData): Promise<AxiosResponse<Artist>> {
  return api.post('/v1/artists', data);
}

export async function editArtist(id: string, data: CreateArtistData): Promise<AxiosResponse<Artist>> {
  return api.put(`/v1/artists/${id}`, data);
}

export async function desactivateArtist(id: string): Promise<AxiosResponse<void>> {
  return api.delete(`/v1/artists/${id}`);
}
