import 'assets/scss/style.scss';

import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import { appConfig } from 'config/app';

import { store } from 'shared/store/initializeStore';

TagManager.initialize({
  gtmId: 'GTM-NNP9F5F3',
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={appConfig.basename}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
