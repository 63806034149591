import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Cart = Loadable(lazy(() => import('modules/cart/pages/Cart')));

const cartRoutes = [
  {
    path: '/cart',
    element: <Cart />,
  },
];

export default cartRoutes;
