import { List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GenericCardProps, NavigationOptions } from 'interfaces/global.interface';

import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: string | React.ReactNode;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    hash?: string;
    url?: string | NavigationOptions;
  };
  isNavList?: boolean;
}

const NavGroup: React.FC<NavGroupProps> = ({ item, isNavList = false }) => {
  const theme = useTheme();

  const items = item.children?.map(menu => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem isNavList={isNavList} key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        style={{ display: isNavList ? 'flex' : 'block', padding: 0 }}
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }>
        {items}
      </List>
    </>
  );
};

export default NavGroup;
