import { IconUsers, IconMusic, IconNotification, IconHome, IconPhone } from '@tabler/icons';

const main = {
  id: 'main-route',
  type: 'group',
  pattern: 'module.home.index',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      pattern: 'home.module.show',
      url: 'home',
      hash: 'main',
      icon: IconHome,
    },
    {
      id: 'releases',
      title: 'Obras',
      type: 'item',
      pattern: 'releases.module.show',
      url: '/releases',
      icon: IconNotification,
    },
    {
      id: 'genres',
      title: 'Gêneros',
      type: 'item',
      pattern: 'genres.module.show',
      url: '/home#genres',
      hash: 'genres',
      icon: IconMusic,
    },
    {
      id: 'artists',
      title: 'Compositores',
      type: 'collapse',
      pattern: 'artists.module.show',
      url: '/artists',
      icon: IconUsers,
      children: [],
    },
    {
      id: 'externalsongs',
      title: '+ Músicas',
      type: 'item',
      pattern: 'external.songs',
      external: true,
      target: true,
      url: 'https://api.whatsapp.com/send/?phone=558591750043&text=Olá Sombank! Gostaria de solicitar uma obra ou esclarecer uma dúvida.',
      icon: IconPhone,
    },
  ],
};

export default main;
