import NavGroup from 'layout/MainLayout/Sidebar/MenuList/NavGroup';
import navItems from 'nav-items';

import { User } from 'hooks/auth';

type NavListProps = {
  user?: User;
};

export default function NavList({ user }: NavListProps) {
  const navList = navItems.items.map(item => {
    let navItem = item;

    if (user) {
      navItem?.children?.forEach(child => {
        if (child?.unavailableProfiles?.includes(user?.category_type)) {
          navItem = {
            ...item,
            children: item?.children?.filter(item => item?.id !== child?.id),
          };
        }
      });
    }

    return <NavGroup isNavList key={navItem.id} item={navItem} />;
  });

  return <div>{navList}</div>;
}
