import { AxiosResponse } from 'axios';

import { Segment } from 'modules/home/services/get-segments.service';

import api from 'shared/services/api';

import { Artist } from './artists.service';

export type Level = {
  created_at: string;
  currency: string;
  id: string;
  name: string;
  value: number;
};

export type Record = {
  created_at: string;
  deleted_at: null | string;
  id: string;
  name: string;
  song_id: string;
  version_url: string;
  version_external: string;
  version_external_provider: string;
};

export type GetSongsParams = {
  filter?: string;
  perPage?: number;
  page?: number;
  sortOrder?: string;
  sortBy?: string;
};

export type Song = {
  created_at: string;
  id: string;
  name: string;
  description: string;
  customer_id: string;
  album: string;
  lyric: string;
  enable_direct_order: boolean;
  is_highlight: boolean;
  is_authorized: boolean;
  custom_value: number;
  song_level_id: string;
  premiered_at: string;
  banner_url: string | null;
  mp3_url: string | null;
  segment: Segment;
  level: {
    id: string;
    name: 'GOLD' | 'PLATINUM';
    value: number;
  };
  writers: Artist[];
  records: Record[];
};

export type CreateSongData = {
  name: string;
  description: string;
  song_level_id?: string;
  segment_id: string;
  lyric: string;
  custom_value?: number;
  enable_direct_order?: boolean;
  is_highlight?: boolean;
  premiered_at?: string;
  writers: string[];
  song?: File;
  banner?: File;
  spotify_url?: string;
};

export async function getSongs(params?: any): Promise<AxiosResponse<Song[]>> {
  return api.get('/v1/songs', {
    params: {
      sortOrder: 'DESC',
      sortBy: 'is_highlight',
      ...params,
    },
  });
}

export async function getMySongs(params?: GetSongsParams): Promise<AxiosResponse<Song[]>> {
  return api.get('/v1/songs/me', { params });
}

export async function getSongById(id: string): Promise<AxiosResponse<Song>> {
  return api.get(`/v1/songs/${id}`);
}

export async function createSong(data: CreateSongData): Promise<AxiosResponse<Song>> {
  return api.post('/v1/songs', data);
}

export async function editSong(id: string, data: CreateSongData): Promise<AxiosResponse<Song>> {
  return api.put(`/v1/songs/${id}`, data);
}

export async function desactivateSong(id: string): Promise<AxiosResponse<void>> {
  return api.delete(`/v1/songs/${id}`);
}
