import React from 'react';
import { useSelector } from 'react-redux';

import { CartProvider } from '@react-providers/cart';
import { DefaultRootStateProps } from 'interfaces/global.interface';
import { ThemeProvider } from 'styled-components';

import theme from 'shared/styles/themes';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => {
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  return (
    <ThemeProvider theme={theme(customization)}>
      <AuthProvider>
        <ToastProvider>
          <CartProvider storeName="sombank-cart">{children}</CartProvider>
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
