import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Button, Typography } from '@mui/material';
import notFoundImage from 'assets/images/not-found-image.png';

import useWindowSize from 'hooks/useWindowDimensions';

import { AvatarGroup, Content, TextGroup, Wrapper } from './styles';

export type SearchResultsProps = {
  id: string;
  artist?: string | ReactNode;
  plays?: number | string;
  img: string | null;
  music: string;
  type: 'song' | 'artist';
  onClick?: (id: string) => void;
};

export default function SearchResults({ id, artist, img, music, type, onClick }: SearchResultsProps) {
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);

  useEffect(() => {
    if (wrapperRef?.current) {
      setWrapperWidth(wrapperRef?.current?.clientWidth);
    }
  }, [wrapperRef, width]);

  const handleClick = (id: string) => {
    !!onClick && onClick(id);

    const redirectByType = {
      song: `/song-request/${id}`,
      artist: `/releases?artist_id=${id}`,
    };

    navigate(redirectByType[type]);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <AvatarGroup>
        <Avatar src={img || notFoundImage} alt={artist as string} />
      </AvatarGroup>
      <Content isMobile={wrapperWidth <= 400}>
        <TextGroup>
          <Typography variant="h4">{music}</Typography>
          <Typography variant="h5">{artist}</Typography>
        </TextGroup>
        <Button variant="contained" onClick={() => handleClick(id)}>
          {type === 'song' ? 'Solicitar obra' : 'Acessar perfil'}
        </Button>
      </Content>
    </Wrapper>
  );
}
