import { useCallback, useEffect, useState } from 'react';

interface UseSessionStorageProps<T> {
  key: string;
  initialValue: T | null;
}

interface UseSessionStorageReturn<T> {
  state: T;
  setValue: (value: T) => void;
  removeValue: () => void;
}

export function useSessionStorage<T>({ key, initialValue }: UseSessionStorageProps<T>): UseSessionStorageReturn<T> {
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setState(value);
        sessionStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  const removeValue = () => {
    sessionStorage.removeItem(key);
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(key);
    };
  }, []);

  return { state, setValue, removeValue };
}
