import 'react-h5-audio-player/lib/styles.css';

import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DefaultRootStateProps } from 'interfaces/global.interface';
import { NavigationScroll } from 'layout/NavigationScroll';
import { Routes } from 'routes';

import { WhatsAppButton } from 'shared/components/WhatsAppButton';
import { theme } from 'shared/styles/themes';

import AppProvider from 'hooks';

const App: React.FC = () => {
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AppProvider>
            <Routes />
          </AppProvider>
        </NavigationScroll>
      </ThemeProvider>
      <WhatsAppButton />
    </StyledEngineProvider>
  );
};

export default App;
