import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const SongRequest = Loadable(lazy(() => import('modules/song-request/pages/Index')));

export const songRequestRoutes = [
  {
    path: '/song-request/:id',
    element: <SongRequest />,
  },
];
