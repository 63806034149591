import React, { Ref } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyedObject } from 'interfaces/global.interface';
import { NavManual } from 'layout/MainLayout/Sidebar/MenuList/NavManual';

const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  titleManual?: string;
  elevation?: number;
  title?: React.ReactNode | string;
}

const MainCard = React.forwardRef(
  (
    {
      border = true,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      sx = {},
      title,
      titleManual,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    return (
      <>
        {titleManual && <NavManual title={titleManual} />}
        <Card
          ref={ref}
          {...others}
          sx={{
            border: border ? '1px solid' : 'none',
            borderColor:
              theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
            ...sx,
          }}>
          {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
          {darkTitle && title && (
            <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
          )}

          {title && <Divider />}

          {content && (
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          )}
          {!content && children}
        </Card>
      </>
    );
  },
);

export default MainCard;
