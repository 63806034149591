import { NavItemType } from 'interfaces/global.interface';

import main from './main';
import mobileMain from './mobileMain';
// import manager from './manager';

const menuItems: { items: NavItemType[] } = {
  // items: [main, manager], usar para diferenciar os menus de acordo com as permissões do user
  items: [main],
};

const menuItemsMobile: { items: NavItemType[] } = {
  // items: [main, manager], usar para diferenciar os menus de acordo com as permissões do user
  items: [mobileMain],
};

export { menuItems, menuItemsMobile };
