import { ReactNode } from 'react';

// eslint-disable-next-line
import { IconButton, Typography } from '@mui/material';
// eslint-disable-next-line
import { IconDots } from '@tabler/icons';
import notFoundImage from 'assets/images/not-found-image.png';

import { AvatarGroup, Content, Wrapper } from './styles';

type ArtistNavItemProps = {
  id?: string;
  title?: string | ReactNode;
  segmentName?: string;
  img?: string;
};

export default function ArtistNavItem({ title, segmentName, img }: ArtistNavItemProps) {
  return (
    <Wrapper>
      <AvatarGroup>
        <img src={img || notFoundImage} alt={title as string} />
      </AvatarGroup>
      <Content>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h5">{segmentName}</Typography>
      </Content>
      {/* <IconButton>
        <IconDots size={10} />
      </IconButton> */}
    </Wrapper>
  );
}
