import { ActionType } from 'typesafe-actions';

import * as songsActions from './actions';

export enum SongsTypes {
  SONGS_GET = '@Songs/SONGS_GET',
  SONGS_GET_SUCCESS = '@Songs/SONGS_GET_SUCCESS',
  SONGS_GET_ERROR = '@Songs/SONGS_GET_ERROR',
  SONGS_PLAY_SONG = '@Songs/SONGS_PLAY_SONG',
}

export type SongsStateReducerAction = ActionType<typeof songsActions>;
