import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Releases = Loadable(lazy(() => import('modules/releases/pages/Index')));

export const releasesRoutes = [
  {
    path: '/releases',
    element: <Releases />,
  },
];
