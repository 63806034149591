import { MinimalLayout } from 'layout/MinimalLayout';
import { NavMotion } from 'layout/NavMotion';
import { GuestGuard } from 'routes/guard/GuestGuard';

import { authorizationRouter } from 'modules/authorization/routes/index.routes';

export const AuthRoutes = {
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [...authorizationRouter],
};
