import { IconButton, styled } from '@mui/material';
import { Box, shouldForwardProp } from '@mui/system';

export const NotificationButton = styled(IconButton, { shouldForwardProp })(({ theme }) => ({
  background: theme.colors.darkPrimaryMain,
  borderRadius: '8px',
  margin: '0 20px',
  svg: {
    width: '24px',
    height: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 16px 0 0',
    svg: {
      width: '16px',
      height: '16px',
    },
  },
  ':hover': {
    background: theme.colors.darkPrimaryMain,
  },
}));

export const NavBox = styled(Box, { shouldForwardProp })(() => ({
  display: 'none',
}));
